import Button from '@atlaskit/button';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { useEffect } from 'react';

import { notify } from 'components/notification';
import { useModalHandlers } from 'hooks';

export default function VersionChecker() {
  const { isOpen, openModal } = useModalHandlers();

  useEffect(() => {
    const checkVersion = () => {
      fetch('/version.txt', {
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
      })
        .then((response) => response.text())
        .then((data) => {
          const version = `${data}`.trim();
          const commitHash = `${process.env.REACT_APP_GIT_COMMIT_HASH}`.trim();
          console.log({ version, commitHash });

          if (!version || version.length !== 40) return;
          if (!version.includes(commitHash)) {
            openModal();
          }
        })
        .catch(() => {
          notify({ content: 'Ensure your VPN is on', type: 'error' });
        });
    };

    checkVersion();
    window.addEventListener('focus', checkVersion);

    return () => {
      window.removeEventListener('focus', checkVersion);
    };
  }, [openModal]);

  return (
    <ModalTransition>
      {isOpen ? (
        <Modal shouldCloseOnOverlayClick={false} shouldCloseOnEscapePress={false}>
          <ModalHeader>
            <ModalTitle>Update available!</ModalTitle>
          </ModalHeader>
          <ModalBody>Please reload the app to apply the latest changes.</ModalBody>
          <ModalFooter>
            <Button appearance="primary" onClick={() => window.location.reload()}>
              Reload
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </ModalTransition>
  );
}
